<template>
  <div id="app">
    <router-view    />
  </div>
</template>
<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
  export default {
  name: 'App',
  components: {
  },
  mounted() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    if(window.location.href.indexOf('collectCustInfo') ==-1){
        if (isMobile) {
          this.$router.push({ path:"wxIndex" }).catch(()=> {  })
        } else {
          this.$router.push({ path:'index' }).catch(()=> {  })
        }
    }







  }
}
</script>

<style>

body {
  margin: 0;
  min-height: 100%;
  overflow-x: scroll;
}

</style>
