import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
    {
        path : '',
        name : 'index',
        component: (resolve) => require(['@/views/index'], resolve),
        meta: { title: '杭州好面科技有限公司'  }
    }, {
        path : '/index',
        name : 'index',
        component: (resolve) => require(['@/views/index'], resolve),
        meta: { title: '杭州好面科技有限公司'  }
    },  {
        path : '/wxIndex',
        name : 'wxIndex',
        component: (resolve) => require(['@/views/wxIndex'], resolve),
        meta: { title: '杭州好面科技有限公司'  }
    },
    {
        path : '/products',
        name : 'products',
        component: (resolve) => require(['@/views/products'], resolve),
        meta: { title: '产品展示'  }
    },
    {
        path : '/wxProducts',
        name : 'wxProducts',
        component: (resolve) => require(['@/views/wxProducts'], resolve),
        meta: { title: '产品展示'  }
    },
    {
        path : '/prodDetail',
        name : 'prodDetail',
        component: (resolve) => require(['@/views/prodDetail'], resolve),
        meta: { title: '产品展示'  }
    },
    {
        path : '/wxProdDetail',
        name : 'wxProdDetail',
        component: (resolve) => require(['@/views/wxProdDetail'], resolve),
        meta: { title: '产品展示'  }
    },
    {
        path : '/quality',
        name : 'quality',
        component: (resolve) => require(['@/views/quality'], resolve),
        meta: { title: '公司资质'  }
    },
    {
        path : '/wxQuality',
        name : 'wxQuality',
        component: (resolve) => require(['@/views/wxQuality'], resolve),
        meta: { title: '公司资质'  }
    },{
        path: '/collectCustInfo',
        component: (resolve) => require(['@/views/collectCustInfo'], resolve),
        hidden: true,
        meta: { title: '杭州好面科技有限公司'  }
    },
    {
        path : '/supply',
        name : 'supply',
        component: (resolve) => require(['@/views/supply'], resolve),
        meta: { title: '供应链'  }
    }, {
        path : '/wxSupply',
        name : 'wxSupply',
        component: (resolve) => require(['@/views/wxSupply'], resolve),
        meta: { title: '供应链'  }
    }, {
        path : '/news',
        name : 'news',
        component: (resolve) => require(['@/views/news'], resolve),
        meta: { title: '新闻中心'  }
    },{
        path : '/newsDetail',
        name : 'newsDetail',
        component: (resolve) => require(['@/views/news/newsDetail'], resolve),
        meta: { title: '新闻中心'  }
    }, {
        path : '/wxNews',
        name : 'wxNews',
        component: (resolve) => require(['@/views/wxNews'], resolve),
        meta: { title: '新闻中心'  }
    },{
        path : '/wxNewsDetail',
        name : 'wxNewsDetail',
        component: (resolve) => require(['@/views/wxNews/wxNewsDetail'], resolve),
        meta: { title: '新闻中心'  }
    },{
        path : '/contact',
        name : 'contact',
        component: (resolve) => require(['@/views/contact'], resolve),
        meta: { title: '关于我们'  }
    },{
        path : '/wxContact',
        name : 'wxContact',
        component: (resolve) => require(['@/views/wxContact'], resolve),
        meta: { title: '关于我们'  }
    },
]
export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

